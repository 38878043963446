import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    height: 100vh;
    width: 100%;
    background: url(/hero-image.jpg) no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    position: relative;  
`;

const QuoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
  align-items: start;
  justify-content: center;
  margin-left: 110px;
  
  @media (max-width: 992px) {
    width: 300px;
    text-align: center;
    margin: auto;
  }
`

const Quote = styled.h1`
  color: #fff;
  font-family: "Playfair Display", serif;
  font-weight: 700;
  
  @media (min-width: 1200px) {
    font-size: 40px;
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 32px;
  }
  
  @media (max-width: 992px) {
    font-size: 24px;
  }
`

const QuoteAuthor = styled.h2`
  color: #fff;
  margin-top: 40px;
  font-weight: 500;
  font-family: "Playfair Display", serif;
  
  @media (min-width: 1200px) {
    font-size: 35px;
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 25px;
  }
  
  @media (max-width: 992px) {
    font-size: 24px;
  }
  
  @media (max-width: 992px) {
    margin: 40px auto 0 auto;
    font-size: 15px;
  }
`

const Hero = () => (
  <Wrapper>
    <QuoteWrapper>
      <Quote>
        „Żaden problem nie oprze się szturmowi długotrwałego myślenia”
      </Quote>
      <QuoteAuthor>
        - Voltaire
      </QuoteAuthor>
    </QuoteWrapper>
  </Wrapper>
)

export default Hero;