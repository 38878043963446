import React from "react"
import GlobalStyles from "../GlobalStyles"
import Header from "../components/Header/Header"
import Hero from "../components/Hero/Hero"
import Content from "../components/Content/Content"
import ContactSection from "../components/ContactSection/ContactSection"
import Footer from "../components/Footer/Footer"
import Helmet from "react-helmet"

const index = () => {
  return (
    <>
      <Helmet>
        <meta name="viewport"
              content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <meta charSet="utf-8" />
        <title>Kancelaria Merda</title>
        <link rel="canonical" href="https://kancelaria-merda.pl/" />
      </Helmet>
      <GlobalStyles />
      <Header />
      <Hero />
      <Content>
        Kancelaria Radcy Prawnego Bartosz Merda świadczy kompleksowe usługi prawne, a w szczególności prowadzi sprawy z zakresu obsługi prawnej Klientów indywidualnych i podmiotów gospodarczych. W funkcjonowaniu Kancelarii szczególny nacisk kładziony jest na fachowość, terminowość i indywidualne podejście do każdego z Klientów oraz efektywność świadczonych usług. Równie ważny jest dobry kontakt z Klientem oraz rzetelne informowanie o wszelkich postępach w sprawie. Dzięki współpracy z kancelariami radców prawnych na terenie województwa opolskiego i dolnośląskiego, Kancelaria świadczy również pomoc prawną na terenie tych województw oraz oczywiście województwa śląskiego.
        <br/>
        <br/> Zapraszam do współpracy.
        <br/>Radca Prawny Bartosz Merda.
      </Content>
      <ContactSection />
      <Footer />
    </>
  )
}

export default index;