import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.section`
  background-color: #F7F0EA;
  padding: 120px 35px;
  width: 100%;
  
  @media (min-width: 992px) and (max-width: 1200px) {
    padding: 50px;
  }
  
  @media (max-width: 992px) {
    padding: 34px;
  } 
`

const Paragraph = styled.p`
  color: #000;
  text-align: justify;
  font-family: "Work Sans", sans-serif; 
  font-weight: 300;
  margin: auto;
  
  @media (min-width: 1200px) {
    max-width: 1000px;
    font-size: 17px;
    line-height: 35px;
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
    max-width: 700px;
    line-height: 22px;
    font-size: 16px;
  }
  
  @media (max-width: 992px) {
    max-width: 600px;
    font-size: 12px;
    line-height: 22px;
  }
`;

const Content = ({children}) => (
  <Wrapper>
    <Paragraph>
      {children}
    </Paragraph>
  </Wrapper>
)

export default Content;