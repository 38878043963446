import React, { useState } from "react"
import styled, { keyframes } from "styled-components";
import { fadeInDown } from 'react-animations';

const Wrapper = styled.div`
  background-color: #d9c8b4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  
  @media (min-width: 1200px) {
    padding: 100px 25px;
  }
  
  @media (max-width: 992px) {
    padding: 40px 0;
  }
 
`

const Paragraph = styled.p`
  font-family: "Work Sans", sans-serif;
  text-align: center;
  color: #000000;
  max-width: 795px;
  font-weight: 300;
  line-height: 35px;
  
  @media (min-width: 1200px) {
      font-size: 30px;
      margin-bottom: 82px;
      line-height: 40px;
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
    padding: 20px;
    margin-bottom: 35px;
    font-size: 22px;
    max-width: 500px;
    line-height: 30px;
  }
  
  @media (max-width: 992px) {
    font-size: 16px;
    max-width: 450px;
    padding: 0 30px;
  }
`

const Hr = styled.hr`
  width: 167px;
  height: 1px;
  border: none;
  background: #000;
  
  @media (max-width: 992px) {
    width: 100px;
  }
`

const IconsWrapper = styled.div`
  margin-top: 98px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  width: 1000px;
  position: relative;
  
  @media (max-width: 1200px) {
    width: 550px;
    margin-top: 50px;
  }
  
  @media (max-width: 992px) {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 100px 100px;
    grid-row: auto auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    width: auto;
  }
`

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  cursor: pointer;
`

const Icon = styled.img`
  margin-bottom: 20px;
  height: 50px;
  
  @media (max-width: 1200px) {
   height: 40px;
   margin-bottom: 15px;
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {

  }
`

const IconLabel = styled.p`
  font-size: 24px;
  font-family: "Work Sans", sans-serif;
  text-align: center;
  color: #000000;
  margin: 0;
  
  @media (max-width: 1200px) {
   font-size: 15px;
  }
`

const fadeInDownAnimation = keyframes`${fadeInDown}`;

const FadeInDownDiv = styled.div`
  animation: 1s ${fadeInDownAnimation};
  position: absolute;
  font-family: "Work Sans", sans-serif;
  cursor: text;

  @media (min-width: 1200px) {
    top: 110px;
  }
  
  @media (max-width: 1200px) {
    font-size: 11px;
    top: 77px;
  }

`;

const ContactSection = () => {
  const [mailAnimation, setMailAnimation] = useState(false);
  const [phoneAnimation, setPhoneAnimation] = useState(false);
  const [facebookAnimation, setFacebookAnimation] = useState(false);

  return (
    <Wrapper>
      <Paragraph>
        Ze względu na aktualną sytuację zachęcamy do kontaktu telefonicznego lub online
      </Paragraph>
      <Hr />
      <IconsWrapper>
        <IconWrapper onClick={() => setMailAnimation(true)}>
          <Icon src="/mail.svg" />
          <IconLabel>
            Mail
          </IconLabel>
          {mailAnimation && <FadeInDownDiv>kancelaria@radcabm.pl</FadeInDownDiv>}
        </IconWrapper>
        <IconWrapper onClick={() => setPhoneAnimation(true)}>
          <Icon src="/phone.svg" />
          <IconLabel>
            Telefon
          </IconLabel>
          {phoneAnimation && <FadeInDownDiv>508 343 810</FadeInDownDiv>}
        </IconWrapper>
        <IconWrapper onClick={() => window.open('https://www.facebook.com/kancelaria.radcaprawny/', "_blank") || window.location.replace('https://www.facebook.com/kancelaria.radcaprawny/')} >
          <Icon src="/facebook.svg" />
          <IconLabel>
            Facebook
          </IconLabel>
        </IconWrapper>
        <IconWrapper>
          <Icon src="/whatsapp.svg" />
          <IconLabel>
            Whatsapp
          </IconLabel>
        </IconWrapper>
      </IconsWrapper>
    </Wrapper>
  )
}

export default ContactSection